export default [
  {
    title: 'service1',
    ru: {
      text: 'home.cost_from',
      price: '400 000 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '400 000 EUR',
    },
    type: 'top',
    img: 's1',
    link: 'citizenship-realestate-turkey',
  },
  {
    title: 'service2',
    ru: {
      text: 'home.cost_from',
      price: '25 000 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '25 000 EUR',
    },
    img: 's2',
    numberOfViews: '840',
    type: 'service',
    rate: 5,
    link: 'residence-permit-france',
  },
  {
    title: 'service3',
    ru: {
      text: 'home.cost_from',
      price: '563 000 USD',
    },
    en: {
      text: 'home.cost_min',
      price: '563 000 USD',
    },
    type: 'service',
    img: 's3',
    numberOfViews: '840',
    rate: 5,
    link: 'golden-visa-business-portugal',
  },
  {
    title: 'service4',
    ru: {
      text: 'home.cost_from',
      price: '155 000 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '155 000 EUR',
    },
    type: 'program',
    img: 's4',
    numberOfViews: '860',
    rate: 5,
    link: 'residence-permit-realestate-montenegro',
  },
  {
    title: 'service5',
    ru: {
      text: 'home.cost_from',
      price: '3 500 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '5 000 EUR',
    },
    type: 'exclusive',
    img: 'business-serbia',
    numberOfViews: '2920',
    rate: 5,
    link: 'residence-permit-business-serbia',
  },
  {
    title: 'service6',
    ru: {
      text: 'home.cost_from',
      price: '6600 USD',
    },
    en: {
      text: 'home.cost_from',
      price: '6600 USD',
    },
    type: 'top',
    img: 's6',
    link: 'residence-permit-business-uae',
  },
  {
    title: 'service7',
    ru: {
      text: 'home.cost_from',
      price: '280 000 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '280 000 EUR',
    },
    type: 'service',
    img: 's7',
    numberOfViews: '840',
    rate: 4,
    link: 'residence-permit-realestate-portugal',
  },
  {
    title: 'service8',
    ru: {
      text: 'home.cost_from',
      price: '450 000 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '450 000 EUR',
    },
    type: 'program',
    img: 's8',
    numberOfViews: '756',
    rate: 4,
    link: 'citizenship-realestate-montenegro',
  },
  {
    title: 'service9',
    ru: {
      text: 'home.cost_from',
      price: '75 000 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '75 000 EUR',
    },
    type: 'service',
    img: 'realestate-turkey',
    numberOfViews: '824',
    rate: 5,
    link: 'residence-permit-realestate-turkey',
  },
  {
    title: 'service10',
    ru: {
      text: 'home.cost_from',
      price: '1 200 EUR',
    },
    en: {
      text: 'home.cost_min',
      price: '1 200 EUR',
    },
    type: 'service',
    img: 's10',
    numberOfViews: '2322',
    rate: 5,
    link: 'residence-permit-business-montenegro',
  },
];
