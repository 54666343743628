import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import Layout from 'shared/modules/Layout';
import action from 'store/allCountries';
import action_search_init from 'store/searchInit';
import HomePage from 'components/Home';
import ListNewsAPI from 'services/ListNewsAPI';
import WpBlogAPI from 'services/WpBlogAPI';

const Home = (props) => {
  const { countriesData = [], searchInitCategories = [] } = props;
  const { t } = useTranslation('home');
  const dispatch = useDispatch();

  useEffect(() => {
    !countriesData.length && dispatch(action.fetchAllCountries.trigger());
    !searchInitCategories.length && dispatch(action_search_init.fetchSearchInit.trigger());
  }, []);

  return (
    <Layout title={`iWorld - ${t('home.filter.subTitle')}`} description={t('home.metaDescription')}>
      <Head>
        <link rel="alternate" hrefLang="x-default" href="https://iworld.com/en" />
        <link rel="alternate" hrefLang="en" href="https://iworld.com/en" />
        <link rel="alternate" hrefLang="ru" href="https://iworld.com/ru" />
      </Head>
      <HomePage {...props} />
    </Layout>
  );
};

// export async function getStaticProps() {
//   return {
//     props: {},
//     revalidate: 100,
//   };
// }

export async function getServerSideProps({ locale, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  const langInHeader = locale;
  const data = { newsList: [], blogList:[] };

  const shortCodeDate = (text) => text.replace('[current_year]', new Date().getFullYear())

  try {
    const newsList = await ListNewsAPI.getPopularListNews({
      query: { per_page: 6 },
      langInHeader: langInHeader,
    });
    data.newsList = newsList.data?.data;

    const blogList = await WpBlogAPI.getWbBlogMainPage(langInHeader);
    data.blogList = blogList.data.map(({post_date, post_name, post_title }) => ({
      postDate:post_date,
      postName:shortCodeDate(post_name),
      postTitle:shortCodeDate(post_title)
    }))

  } catch (e) {
    console.log('Errors Home api: ', e);
  }
  return {
    props: data,
  };
}


Home.propTypes = {
  countriesData: PropTypes.array,
  countryGroupsData: PropTypes.array,
  newsList: PropTypes.array,
  blogList: PropTypes.array,
};

export default Home;
